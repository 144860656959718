<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-09 17:36:59
 * @LastEditTime: 2022-09-19 19:17:00
 * @LastEditors: cyy
 * @Description: 导航设置
 * @FilePath: \teacherdingding.com\src\views\NetSchollAdmin\ComputerScholl\components\navigationSetting.vue
-->
<template>
  <div id="navigationSetting">
    <div class="title">导航栏预览</div>
    <div class="yulan" ref="yulan">
      <div class="left">
        <div class="logo">
          <img :src="findlist.logo" alt="" />
        </div>
        <div class="school_name" v-if="findlist.name_status == 1">
          {{ findlist.jg_name }}
        </div>
        <div class="navlist" v-if="!search">
          <div
            class="item"
            v-for="(item, index) in datalist"
            :key="item.id"
            :class="{ bg: index == 0 }"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="search" v-if="search">
          <img
            src="https://f.dingdingkaike.com.cn/pcddkk/ico_sousuo.svg"
            class="img"
            alt=""
          />
          <span class="placeholder">输入课程名称，回车搜索</span>
          <img
            class="img"
            @click="search = false"
            src="https://f.dingdingkaike.com.cn/pcddkk/icon_wx_qxss.svg"
            alt=""
          />
        </div>
      </div>
      <div class="right">
        <div class="navlist2">
          <div
            class="item"
            v-if="findlist.search_status == 1"
            @click="search = !search"
          >
            <img
              src="https://f.dingdingkaike.com.cn/pcddkk/ico_sousuo.svg"
              class="img"
              alt=""
              title="搜索"
            />
          </div>
          <div class="item" v-if="findlist.member_status == 1">
            <img
              src="https://f.dingdingkaike.com.cn/pcddkk/icon_vip18.svg"
              class="img"
              alt=""
              title="会员专区"
            />
          </div>
          <div class="item" v-if="findlist.browse_status == 1">
            <img
              src="https://f.dingdingkaike.com.cn/pcddkk/icon_zjll.svg"
              class="img"
              alt=""
              title="最近浏览"
            />
          </div>
          <div class="item" v-if="findlist.course_status == 1">
            <img
              src="https://f.dingdingkaike.com.cn/pcddkk/icon_wdkc.svg"
              class="img"
              alt="没有图"
              title="我的课程"
            />
          </div>
        </div>
        <div class="uphoto">
          <img :src="findlist.user_photo" alt="" />
        </div>
      </div>
    </div>
    <div class="title mt40">LOGO设置</div>
    <div class="logo_select">
      <div class="pic">
        <img :src="findlist.logo" alt="" />
      </div>
      <div class="btn_contain">
        <el-button>选择</el-button>
        <change-photoimg
          :size="2"
          @complete="completephoto"
          class="changePhotoimg"
        ></change-photoimg>
      </div>
      <div class="logo_tip">
        建议尺寸140*40px，jpg、png格式，图片小于2M。该LOGO仅对PC网校生效，不会作用到网校其他模块
      </div>
    </div>
    <div class="title mt40">其他模块开关</div>
    <div class="switchlist">
      <div class="item">
        <el-switch
          v-model="findlist.name_status"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="sw_name">网校名称</div>
      </div>
      <div class="item">
        <el-switch
          v-model="findlist.search_status"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="sw_name">搜索框</div>
        <img
          src="https://f.dingdingkaike.com.cn/pcddkk/ico_sousuo.svg"
          class="img"
          alt=""
          title="搜索"
        />
      </div>
      <div class="item">
        <el-switch
          v-model="findlist.member_status"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="sw_name">会员专区</div>
        <img
          src="https://f.dingdingkaike.com.cn/pcddkk/icon_vip18.svg"
          class="img"
          alt=""
          title="会员专区"
        />
      </div>
      <div class="item">
        <el-switch
          v-model="findlist.browse_status"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="sw_name">最近浏览</div>
        <img
          src="https://f.dingdingkaike.com.cn/pcddkk/icon_zjll.svg"
          class="img"
          alt=""
          title="最近浏览"
        />
      </div>
      <div class="item">
        <el-switch
          v-model="findlist.course_status"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="sw_name">我的课程</div>
        <img
          src="https://f.dingdingkaike.com.cn/pcddkk/icon_wdkc.svg"
          class="img"
          alt="没有图"
          title="我的课程"
        />
      </div>
    </div>
    <div class="title mt40">导航栏设置</div>
    <div class="new_nav_heard">
      <el-button
        type="primary"
        size="medium"
        :disabled="datalist.length >= findlist.num_all"
        @click="newNavclick"
      >
        新建导航
      </el-button>
      <div class="num">
        导航数量：{{ datalist.length }}/{{ findlist.num_all }}
      </div>
    </div>
    <div class="table">
      <div class="t_row">
        <div class="t_title" style="flex:20%">导航名称</div>
        <div class="t_title" style="flex:60%">跳转链接</div>
        <div class="t_title" style="flex:20%">操作</div>
      </div>

      <transition-group class="ul" tag="ul" name="flip-list">
        <div class="t_item" v-for="(item, index) in datalist" :key="item.id">
          <div class="t_ceil" style="flex:20%">
            <span class="t_label">{{ item.name }}</span>
            <i
              @click="editName(item, index)"
              class="el-icon-edit-outline"
              style="color: #0aa29b; font-size: 14px"
            ></i>
          </div>
          <div class="t_ceil" style="flex:60%">
            <!-- <el-select
              v-model="item.default_type"
              placeholder="请选择"
              size="small"
              style="width: 120px; margin-right: 14px"
              @change="optionChange($event, index)"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select> -->
            <select-link
              :key="index"
              :index="index"
              :value="item"
              @compelet="compelet"
              :ref="`selectlinkRef${index}`"
            ></select-link>
            <div class="flex-center t_label_contain">
              <div
                class="t_label name_label"
                :title="item.link"
                v-if="item.default_type == 5"
              >
                {{ item.link }}
              </div>
              <div class="t_label name_label" :title="item.url_nam" v-else>
                {{ item.url_name }}
              </div>

              <div style="width:50px;flex-shrink: 0;">
                <i
                  class="el-icon-close"
                  @click="clearselect(index)"
                  v-if="item.default_type"
                ></i>
              </div>
            </div>
          </div>
          <div class="t_ceil" style="flex:20%">
            <!-- 上移 | 下移 | 删除 -->
            <div class="btn">
              <el-button
                type="text"
                :disabled="index == 0"
                @click="upDownMove(index, index - 1)"
              >
                上移
              </el-button>
              <span class="dirver">|</span>
              <el-button
                type="text"
                :disabled="index == datalist.length - 1"
                @click="upDownMove(index, index + 1)"
              >
                下移
              </el-button>
              <span class="dirver">|</span>
              <el-button type="text" @click="del(index)">删除</el-button>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <div class="submit mt30 _center">
      <el-button size="medium" @click="allCancel">取消</el-button>
      <el-button type="primary" size="medium" @click="submit" class="ml10">
        保存
      </el-button>
    </div>
    <!-- 新建导航 -->
    <el-dialog
      :title="isedit ? '编辑导航' : '新建导航'"
      :visible.sync="newNav"
      width="422px"
      :close-on-click-modal="false"
    >
      <el-input
        placeholder="请输入导航名称"
        show-word-limit
        maxlength="7"
        v-model.trim="newlist.name"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newNav = false">取 消</el-button>
        <el-button type="primary" @click="isedit ? edit() : addNav()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import selectLink from '@cm/selectLink'
import changePhotoimg from '@cm/base/changePhotoimg'
import resize from '../resize'
export default {
  name: 'navigationSetting',

  mixins: [resize],

  components: {
    changePhotoimg,
    selectLink,
  },

  data() {
    return {
      copydatalist: [],
      copydatalist2: [],
      // 新建导航
      newNav: false,
      search: false,
      newlist: {
        id: Math.random(),
        name: '',
        commodity_type: '',
        set_id: '',
        default_type: '',
        link: '',
        url_name: '',
      },
      findlist: {},
      datalist: [],
      isedit: false,
      value: 1,
    }
  },

  created() {
    this.getNavbarComponentList()
  },

  methods: {
    newNavclick() {
      this.resetNewlist()
      this.newNav = true
    },

    compelet({ val, index }) {
      console.log('compelet', val)
      const obj = _.merge({}, val)
      this.datalist[index] = obj
      this.$forceUpdate()
    },

    async submit() {
      // 判断是否没有选择的
      const empty = this.datalist.some(item => !item.default_type)
      if (empty) {
        this.$root.prompt('请先为导航名称选择跳转链接！')
        return
      }
      await this.$http({
        url: '/PcHomeTemplate/navbarComponentSave',
        data: _.assign({}, this.findlist, { list: this.datalist || [] }),
        successMsg: true,
      })
      this.getNavbarComponentList()
    },

    //   取消全部的更改
    allCancel() {
      this.getNavbarComponentList()
    },

    // 删除
    del(index) {
      this.$confirm('是否确定删除该导航？', '删除导航', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.datalist.splice(index, 1)
        })
        .catch(() => {})
    },

    // 清除
    clearselect(index) {
      const obj = _.assign({}, this.datalist[index], {
        commodity_type: '',
        set_id: '',
        default_type: '',
        link: '',
        url_name: '',
      })
      this.datalist[index] = obj
      // 清除的时候组件的备用数据也要清理掉,_.merge({}, obj)防止与 this.datalist[index]指向同一个地址
      this.$refs[`selectlinkRef${index}`][0].copylist = _.merge({}, obj)
      this.$forceUpdate()
    },

    // 编辑
    edit() {
      this.datalist[this.editIndex] = _.merge({}, this.newlist)
      this.resetNewlist()
      this.isedit = false
    },

    // 编辑名称
    editName(item, index) {
      this.editIndex = index
      this.newNav = true
      this.isedit = true
      this.newlist = _.merge({}, item)
    },

    // 重置新建数据
    resetNewlist() {
      this.newNav = false
      this.newlist = {
        name: '',
        commodity_type: '',
        set_id: '',
        default_type: '',
        link: '',
        id: Math.random(),
        url_name: '',
      }
    },

    // 添加导航
    addNav() {
      this.datalist.push(this.newlist)
      this.resetNewlist()
    },

    // 上移 下移
    upDownMove(index, newIndex) {
      if (index === 0 && newIndex < index) return
      if (index === this.datalist.length - 1 && newIndex > index) return

      const oldData = this.datalist[newIndex]
      this.datalist.splice(newIndex, 1, this.datalist[index])
      this.datalist.splice(index, 1, oldData)
    },

    //   获取数据
    async getNavbarComponentList() {
      const { find, data } = await this.$http({
        url: '/PcHomeTemplate/navbarComponentList',
        data: {},
      })
      this.findlist = find
      this.datalist = data
      this.copydatalist = _.merge([], this.datalist)
      this.copydatalist2 = _.merge([], this.datalist)
    },

    //  获取到的图片
    completephoto(val) {
      this.findlist.logo = val
    },
  },
}
</script>

<style lang="scss" scoped>
#navigationSetting {
  padding-bottom: 20px;
  .flip-list-move {
    transition: transform 0.35s;
  }

  .title {
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
  }
  .yulan {
    height: 44px;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid #fafafa;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      flex: 1;
      display: flex;
      position: relative;
      align-items: center;
      .logo {
        flex-shrink: 0;
        margin-right: 9px;
        max-width: 140px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: contain;
        }
      }
      .school_name {
        flex-shrink: 0;
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 44px;
        vertical-align: middle;
      }
      .navlist {
        margin-left: 45px;
        display: flex;
        .item {
          padding: 0 15px;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: normal;
          color: #333333;
          line-height: 44px;
          white-space: nowrap;
        }
        .bg {
          color: #0aa29b;
          font-weight: bold;
          background-color: #fafafa;
          border-bottom: 1px solid #0aa29b;
        }
      }
      .search {
        height: 50px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        width: 80%;
        position: absolute;
        right: 0;
        .img {
          height: 12px;
          width: 12px;
          margin-right: 10px;
          &:last-child {
            cursor: pointer;
          }
        }
        .placeholder {
          flex: 1;
          font-size: 12px;
          color: #a8a8a8;
          line-height: 22px;
          vertical-align: middle;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .navlist2 {
        display: flex;
        align-items: center;
        .item {
          cursor: pointer;
          padding: 13px 17px;
          .img {
            height: 14px;
          }
        }
      }
      .uphoto {
        width: 25px;
        height: 25px;
        border-radius: 13px;
        margin-left: 11px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .logo_select {
    display: flex;
    align-items: center;
    .pic {
      width: 140px;
      height: 40px;
      background: #f5f5f5;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .el-button {
      background-color: #f5f5f5;
      width: 100px;
    }
    .logo_tip {
      font-size: 13px;
      color: #999999;
      line-height: 17px;
      margin-left: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .btn_contain {
    position: relative;
    .changePhotoimg {
      height: 40px !important;
      position: absolute;
      top: 0;
    }
  }
  .switchlist {
    display: flex;
    align-items: center;
    .item {
      margin-right: 50px;
      display: flex;
      align-items: center;
      .sw_name {
        font-size: 14px;
        color: #333333;
        margin-left: 12px;
      }
      .img {
        height: 14px;
        margin-left: 10px;
      }
    }
  }
  .new_nav_heard {
    display: flex;
    align-items: center;
    .num {
      margin-left: 20px;
      font-size: 14px;
      color: #333333;
    }
  }
  .table {
    margin-top: 10px;
    .t_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 42px;
      background: #f5f5f5;
      border-radius: 1px;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      .t_title {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }
    .t_item {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      border-bottom: 1px #f5f5f5 solid;
      .t_ceil {
        display: flex;
        align-items: center;
        .dirver {
          color: #0aa29b;
          margin: 0 5px;
          font-size: 14px;
        }
        .t_label_contain {
          width: 250px;
          .el-icon-close {
            cursor: pointer;
            font-size: 14px;
            padding: 10px;
            display: none;
          }
          &:hover .el-icon-close {
            display: block;
          }
        }

        .name_label {
          overflow: hidden;
          white-space: nowrap;
          word-break: break-all;
          text-overflow: ellipsis;
        }

        .t_label {
          font-size: 14px;
          color: #333333;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
