<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-09 16:13:16
 * @LastEditTime: 2022-05-09 17:39:30
 * @LastEditors: cyy
 * @Description: 登陆设置
 * @FilePath: \teacher.dingdingkaike\src\views\NetSchollAdmin\ComputerScholl\components\loginSetting.vue
-->
<template>
  <div id="loginSetting">
    <div class="setting-contain">
      <div class="login_title">电脑网校登录方式</div>
      <el-checkbox-group
        v-model="checkboxGroup1"
        size="small"
        @change="checkboxGroupChange"
        :min="1"
      >
        <el-checkbox label="1">手机号+密码</el-checkbox>
        <el-checkbox label="2">手机号+短信验证码</el-checkbox>
        <el-checkbox label="3">微信登录</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'loginSetting',
  data() {
    return {
      checkboxGroup1: [],
    }
  },

  computed: {
    ...mapState(['userInfo']),
    login_mode() {
      return this.userInfo.login_mode
    },
  },

  created() {
    this.checkboxGroup1 = _.merge([], this.login_mode)
  },
  methods: {
    ...mapActions(['getUserInfo']),

    async checkboxGroupChange(val) {
      await this.$http({
        url: '/user/setPcLogin',
        data: {
          login_mode: val,
        },
      })
      this.$store.dispatch('getUserInfo')
    },
  },
}
</script>

<style lang="scss" scoped>
#loginSetting {
  padding: 20px;
  .login_title {
    margin-bottom: 20px;
    font-size: 14px;
    color: #333333;
  }
}
</style>