<!--
 * @Author: cyy
 * @version: 
 * @Date: 2021-03-06 00:26:18
 * @LastEditTime: 2022-05-09 17:38:55
 * @LastEditors: cyy
 * @Description:
 * @FilePath: \teacher.dingdingkaike\src\views\NetSchollAdmin\ComputerScholl\ComputerScholl.vue
-->
<template>
  <div class="computer-scholl">
    <!--网校未开通-->
    <template v-if="!isOpenpcHome">
      <el-card class="box-card">
        <div>
          <dl>
            <dt class="head-img">
              <img src="@ass/img/NetSchollAdmin/img_pcwxico.png" alt="" />
            </dt>
            <dd>
              <h4>{{ name }}</h4>
            </dd>
            <dd>{{ introduceContent }}</dd>
            <dd>
              <el-button @click="dialogVisible = true">立即开通</el-button>
              <span class="money">
                <b>￥{{ money }}/年</b>
              </span>
            </dd>
          </dl>
          <div class="introduce">
            <img :src="imgUrl" class="introduce-img" />
          </div>
        </div>
      </el-card>
      <el-dialog title="开通PC网校" :visible.sync="dialogVisible" width="30%">
        <div style="text-align: center">扫码添加微信客服开通</div>
        <div class="erweima">
          <img style="width: 100%" :src="serviceUrl" />
        </div>
      </el-dialog>
    </template>

    <!--开通了-->
    <template v-else>
      <el-card class="box-card box-card2">
        <el-tabs v-model="activeName">
          <el-tab-pane label="页面设置" name="1"></el-tab-pane>
          <el-tab-pane label="导航设置" name="2"></el-tab-pane>
          <el-tab-pane label="登录设置" name="3"></el-tab-pane>
        </el-tabs>
        <!-- 网校装修 -->
        <renovation v-if="activeName == 1"></renovation>
        <!-- 导航设置 -->
        <navigation-setting v-if="activeName == 2"></navigation-setting>
        <!-- 登陆设置 -->
        <login-setting v-if="activeName == 3"></login-setting>
      </el-card>
    </template>
  </div>
</template>

<script>
import navigationSetting from './components/navigationSetting'
import loginSetting from './components/loginSetting.vue'
import renovation from './components/renovation.vue'
import { mapState, mapGetters } from 'vuex'
import bg from '@/mixin/background'

export default {
  name: 'pcHome',

  mixins: [bg],

  components: {
    renovation,
    loginSetting,
    navigationSetting,
  },

  data() {
    return {
      activeName: '1',
      name: 'PC网校官网',
      introduceContent:
        '无需搭建，开通即可获得独立品牌官网；完美兼容，与移动端无缝对接',
      btnContent: '立即开通', //开通按钮
      money: 1599, //开通价格
      imgUrl:
        'https://client.dingdingkaike.com/teacher.dingdingkaike.banner1.png?imageslim', //网校介绍图
    }
  },

  computed: {
    ...mapState(['userInfo']),
    ...mapGetters(['isOpenpcHome']),
  },
}
</script>

<style lang="less" scoped>
.computer-scholl {
  margin: 20px;
}
::v-deep .settingDialogVisible {
  .el-dialog__body {
    padding-top: 10px !important;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
}

.head-img {
  float: left;
  margin-bottom: 20px;
}
dl {
  margin: 20px;
  margin-bottom: 30px;
}
dd {
  line-height: 25px;
  text-indent: 30px;
}
dd:nth-child(1) {
  margin-top: 35px;
}
dd:nth-child(3) {
  font-size: 13px;
  color: #808080;
}
dd:nth-child(4) {
  margin-top: 20px;
}
.introduce {
  width: 100%;
  padding-top: 54px;
  // overflow: hidden;
}
/deep/ .el-card__body {
  padding: 0px;
  // height: 1480px;
}

/deep/ .el-dialog__title {
  font-size: 15px;
}

/deep/ .el-card.box-card.is-always-shadow {
  overflow: inherit;
}
.erweima {
  width: 160px;
  height: 160px;
  margin: 40px auto;
  border: 1px #ccc dashed;
}
.money {
  color: #ff3535;
  margin-left: 20px;
}
.introduce-img {
  width: 833px;
  margin: 0 auto;
}
.pc-wx-url {
  font-size: 12px;
  line-height: 22px;
  margin-left: 20px;
}
.copy-link {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-left: 20px;
  cursor: pointer;
  color: rgba(27, 157, 151, 1);
}
/deep/ .box-card2 {
  padding: 0 20px;
  .el-tabs {
    background: #fff;
    .el-tabs__item {
      height: 44px;
      line-height: 44px;
    }
  }
  .flex-center {
    .btn,
    .space {
      font-size: 14px;
      color: #0aa29b;
    }
    .btn {
      cursor: pointer;
      padding: 10px 0;
    }
    .space {
      margin: -2px 10px 0;
    }
  }
}
</style>

