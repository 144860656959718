/*
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-10 10:55:04
 * @LastEditTime: 2022-05-10 11:11:26
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacher.dingdingkaike\src\views\NetSchollAdmin\ComputerScholl\resize.js
 */
export default {
    destroyed() {
        window.removeEventListener('resize', this.onPageResize)
    },
    created() {
        window.addEventListener('resize', this.onPageResize)
        this.onPageResize()
    },

    methods: {
        // 监听页面resize
        onPageResize() {
            requestAnimationFrame(() => {
                const leftWidth = 0
                const rightWidth = 0
                const windowWidth = document.documentElement.clientWidth
                const maxWidth = 1009
                // 剩余空间
                const remainingSpace = windowWidth - leftWidth - rightWidth
                // 剩余充足的空间
                const isPlentySpace = remainingSpace >= maxWidth
                // 内容宽度
                const contentWidth = isPlentySpace ? maxWidth : remainingSpace
                // 如果剩余空间 大于 最大宽度
                const scale = contentWidth / maxWidth
                const transform = `scale(${scale})`
                this.transformStyle = `transform: ${transform}`
                this.$refs.yulan.style = this.transformStyle
            })
        },
    }

}