<!--
 * @Author: cyy
 * @version: 
 * @Date: 2021-03-06 00:26:18
 * @LastEditTime: 2022-10-11 14:46:13
 * @LastEditors: cyy
 * @Description: 电脑网校
 * @FilePath: \teacher.dingdingkaike\src\views\NetSchollAdmin\ComputerScholl\components\renovation.vue
-->
<template>
  <div id="SchollPreview">
    <div class="header">
      <div class="left">
        <el-button
          type="primary"
          size="medium"
          @click="newpage"
          :disabled="completelist.num_new >= completelist.num_all"
        >
          新建页面
        </el-button>
        <div class="number">
          页面数量：{{ completelist.num_new }}/{{ completelist.num_all }}
        </div>
      </div>
      <div class="right">
        <el-input
          v-on:keyup.enter.native="search"
          v-model="input"
          placeholder="搜索页面"
          class="mr10"
          size="medium"
        ></el-input>
        <el-button type="primary" size="medium" @click="search">搜索</el-button>
      </div>
    </div>
    <pagination2
      url="/PcHomeTemplate/templateList"
      ref="childDialogBox"
      class="childDialogBox"
      :option="post2"
      @complete="complete"
      :hadPagination="false"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="页面名称">
            <template slot-scope="{ row }">
              <div class="name_contain">
                <div class="name">
                  {{ row.name }}
                </div>
                <div class="is_home" v-if="row.is_home == 1">首页</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="创建时间" prop="c_time">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>

          <el-table-column label="页面地址" prop="url">
            <template slot-scope="{ row }">
              <div class="tableline" @click="$store.dispatch('open', row.url)">
                {{ row.url }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="480px">
            <template slot-scope="{ row, $index }">
              <div class="btn_contain">
                <!-- 装修  |  设为首页  |  分享  |  更多 -->
                <el-button type="text" @click="decorate(row)">装修</el-button>
                <span class="sp_diver">|</span>
                <el-button
                  type="text"
                  @click="settingindex(row)"
                  :disabled="row.is_home == 1"
                >
                  设为首页
                </el-button>
                <span class="sp_diver">|</span>
                <el-button
                  type="text"
                  :data-clipboard-text="row.url"
                  :class="`address-btn${$index}`"
                >
                  复制链接
                </el-button>
                <span class="sp_diver">|</span>
                <el-button type="text" @click="edit(row)">页面设置</el-button>
                <span class="sp_diver">|</span>
                <el-button
                  type="text"
                  @click="copy(row)"
                  :disabled="completelist.num_new >= completelist.num_all"
                >
                  创建副本
                </el-button>
                <span class="sp_diver">|</span>
                <el-button
                  type="text"
                  @click="delpage(row)"
                  :disabled="row.is_home == 1"
                >
                  删除
                </el-button>

                <!-- <el-dropdown>
                  <span class="sp_name el-dropdown-link">更多</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <p @click="edit(row)">页面设置</p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div
                        style="color: #a1a1a1; cursor: no-drop"
                        v-if="completelist.num_new >= completelist.num_all"
                      >
                        创建副本
                      </div>
                      <p @click="copy(row)" v-else>创建副本</p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div
                        style="color: #a1a1a1; cursor: no-drop"
                        v-if="row.is_home == 1"
                      >
                        删除
                      </div>
                      <div v-else @click="delpage(row)">删除</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <!-- 新建页面 -->
    <el-dialog
      top="5%"
      :title="title"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @closed="dialogVisibleclose"
      :destroy-on-close="true"
    >
      <el-form
        ref="form"
        :model="post"
        label-width="120px"
        label-position="left"
      >
        <el-form-item label=" 页面名称" :required="true">
          <el-input
            v-model="post.name"
            placeholder="请输入页面名称"
            show-word-limit
            maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label=" 网页标题">
          <div slot="label" style="margin-left: 5px" class="flex-center">
            网页标题
            <el-popover placement="top-start" width="540" trigger="hover">
              <img
                src="@ass/img/1.4.7/img_whslt.png"
                style="width: 536px; height: 150px"
                alt=""
              />
              <div class="help" slot="reference"></div>
            </el-popover>
          </div>
          <el-input
            type="textarea"
            v-model="post.title"
            placeholder="建议填写品牌名+品牌定位，字数不超过30个字符"
            show-word-limit
            maxlength="30"
            :autosize="{ minRows: 3, maxRows: 3 }"
          ></el-input>
        </el-form-item>
        <el-form-item label=" 网页关键词">
          <div slot="label" style="margin-left: 5px" class="flex-center">
            网页关键词
            <el-popover placement="top-start" width="540" trigger="hover">
              <div>
                <p class="mb10">
                  1.
                  网页关键词是指网页提供内容相关的用词，可以提高网站在各搜索网站的权重，从而提升网站曝光
                </p>
                <p>2. 关键词建议使用与网站提供内容强关联的用词</p>
              </div>
              <div class="help" slot="reference"></div>
            </el-popover>
          </div>
          <!-- <el-tag
            :key="tag"
            v-for="tag in dynamicTags"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
            maxlength="10"
            show-word-limit
          ></el-input>
          <el-button
            v-else-if="dynamicTags.length < 10"
            class="button-new-tag"
            size="small"
            @click="showInput"
          >
            + 添加关键词
          </el-button> -->
          <el-input
            show-word-limit
            maxlength="500"
            type="textarea"
            v-model="post.keywords"
            placeholder="建议填写网校内容相关的名称，比如音乐类的网校可以填写乐器、即兴伴奏等"
            style="width: 582px"
            :autosize="{ minRows: 3, maxRows: 999 }"
          ></el-input>
          <!-- <div class="tip">
            关键词与关键词之间请用英文逗号“,”隔开，最多10个词
          </div> -->
        </el-form-item>
        <el-form-item label="网页描述">
          <div slot="label" style="margin-left: 5px" class="flex-center">
            网页描述
            <el-popover placement="top-start" width="540" trigger="hover">
              <div>
                <p class="mb10">
                  1.网页描述是指网站的内容描述，这个描述不会显示在网站上，可以提高网站在各搜索网站的权重，从而提升网站曝光
                </p>
                <p>2. 描述建议使用与网站提供内容相关的用语</p>
              </div>
              <div class="help" slot="reference"></div>
            </el-popover>
          </div>
          <el-input
            type="textarea"
            v-model="post.description"
            placeholder="建议填写网校的定位，给用户带来什么价值，提供什么样的内容等。示例：叮叮开课，专业的在线教育直播教学系统服务商，在线网校平台，直播教学平台，直播课堂系统,在线授课平台，网校系统，帮助教育培训机构快速搭建专属网校，构建线上线下相结合的全新教育模式。"
            show-word-limit
            maxlength="1000"
            :autosize="{ minRows: 7, maxRows: 999 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addTemplate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分享 -->
    <!-- <el-dialog
      title="分享"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible2"
      width="800px"
      @opened="create_ewm"
    >
      <div class="dialogVisible2_contain">
        
        <div class="left">
          <div id="qcode" ref="qrcode"></div>
          <a :href="imgUrl">
            <el-button type="text">下载二维码</el-button>
          </a>
        </div>
        <div class="right">
          <div class="url">{{ sharelist.url }}</div>
          <el-button
            type="text"
            :data-clipboard-text="sharelist.url"
            class="address-btn"
          >
            复制链接
          </el-button>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
// import QRCode from '@/assets/js/qrcode'
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      // dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      input: '',
      imgUrl: '',
      post2: {
        name: '',
      },
      post: {
        name: '',
        title: '',
        keywords: '',
        description: '',
      },
      title: '新建页面',
      text: '',
      dialogVisible: false,
      // dialogVisible2: false,
      edit_id: '',
      sharelist: {},
      completelist: {},
    }
  },

  components: {},

  mounted() {
    // this.clipboard()
  },

  methods: {
    // handleClose(tag) {
    //   this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    // },

    // showInput() {
    //   this.inputVisible = true
    //   this.$nextTick(() => {
    //     this.$refs.saveTagInput.$refs.input.focus()
    //   })
    // },

    // handleInputConfirm() {
    //   let inputValue = this.inputValue
    //   if (inputValue) {
    //     this.dynamicTags.push(inputValue)
    //   }
    //   this.inputVisible = false
    //   this.inputValue = ''
    // },

    // 新建页面
    newpage() {
      this.title = '新建页面'
      this.dialogVisible = true
    },

    // 获取列表的数据
    complete(val) {
      this.completelist = val
      const data = val.data
      data.forEach((item, index) => {
        this.clipboard(index)
      })
    },

    // 关闭弹窗后，清除文本内容
    dialogVisibleclose() {
      this.post = {
        name: '',
        title: '',
        keywords: '',
        description: '',
      }

      this.edit_id = ''
      // this.dynamicTags = []
    },

    // 新建页面 页面设置
    async addTemplate() {
      if (!this.post.name.trim()) {
        this.$root.prompt({
          msg: '请输入页面名称的',
        })
        return
      }
      // this.post.keywords = this.dynamicTags.join(',')
      if (this.edit_id) {
        await this.$http({
          url: '/PcHomeTemplate/setName',
          data: _.assign({}, this.post, { pc_t_home_id: this.edit_id }),
          successMsg: true,
        })
      } else {
        await this.$http({
          url: '/PcHomeTemplate/addTemplate',
          data: this.post,
          successMsg: true,
        })
      }
      this.dialogVisible = false
      this.$refs.childDialogBox.reset()
    },

    // 创建副本
    async copy(row) {
      await this.$http({
        url: '/PcHomeTemplate/copy',
        data: { pc_t_home_id: row.pc_t_home_id },
        successMsg: '创建副本成功！',
      })
      this.$refs.childDialogBox.reset()
    },

    // 装修
    decorate(val) {
      let url = this.$router.resolve({
        path: '/pcSchoolDecoration',
        query: { id: val.pc_t_home_id, name: val.name },
      })
      this.$store.dispatch('open', url.href)
    },

    // 搜索
    search() {
      this.post2.name = this.input
    },

    // 设置为首页
    settingindex(row) {
      this.$confirm(
        `设为首页后，访问链接 ${this.$store.state.userInfo.pcHomeDomain} 时或点击部分网校页面的【首页】按钮时，会默认进入当前页面！`,
        '设为首页',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(async () => {
          await this.$http({
            url: '/PcHomeTemplate/setIndex',
            data: { pc_t_home_id: row.pc_t_home_id },
            successMsg: '首页设置成功！',
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 删除
    async delpage(row) {
      await this.$confirm(
        '删除后已装修的内容将被清理，无法找回，是否确定删除？',
        '删除微页面',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(async () => {
          await this.$http({
            url: '/PcHomeTemplate/delete',
            data: { pc_t_home_id: row.pc_t_home_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 复制
    clipboard(index) {
      const self = this
      const clipboard = new Clipboard(`.address-btn${index}`)
      clipboard.on('success', () => {
        self.$root.prompt({
          type: 'success',
          msg: '复制成功',
        })
      })
      clipboard.on('error', () => {
        self.$root.prompt('复制失败')
      })
    },

    // 分享
    // share(row) {
    //   this.sharelist = row
    //   this.dialogVisible2 = true
    // },

    // 生成二维码
    // create_ewm() {
    //   const size = 155
    //   //生成前先清空原有的，不然会有多张二维码的情况
    //   this.$refs.qrcode.innerHTML = ''
    //   const node = this.$refs.qrcode
    //   if (node) {
    //     this.qrcode = new QRCode(node, {
    //       text: this.sharelist.url, //地址
    //       width: size,
    //       height: size,
    //       colorDark: '#000000',
    //       colorLight: '#ffffff',
    //       correctLevel: QRCode.CorrectLevel.H,
    //     })
    //   }
    //   this.imgUrl = this.qrcode.getBase64Code()
    // },

    // 编辑
    edit(row) {
      this.dialogVisible = true
      this.title = '页面设置'
      this.edit_id = row.pc_t_home_id
      this.text = row.name
      this.geteditval()
    },

    async geteditval() {
      const { data } = await this.$http({
        url: `/PcHomeTemplate/setName?pc_t_home_id=${this.edit_id}`,
      })
      this.post = data
      // this.dynamicTags =
      //   data.keywords.length > 0 ? data.keywords.split(',') : []
    },
  },
}
</script>

<style lang="less" scoped>
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  // margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 192px !important;
  vertical-align: bottom;
}
::v-deep .el-form-item__content {
  width: 582px;
}

#SchollPreview {
  padding: 20px;
  ::v-deep .el-textarea .el-input__count {
    height: 15px;
    background: transparent !important;
    bottom: 15px;
  }
  ::v-deep .el-textarea textarea {
    padding-bottom: 15px !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 20px;
  }
  .help {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 6px;
    background: url('~@ass/img/1.2.9/ico_wh@2x.png');
    background-size: 100%;
    &.icon2 {
      width: 8px;
      height: 13px;
      background: url('~@ass/img/1.2.9/ico_xljt.png');
      background-size: 100%;
    }
  }

  .header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .number {
        font-size: 14px;
        color: #333333;
        margin-left: 20px;
      }
    }
    .right {
      display: flex;
      align-content: center;
    }
  }
  .childDialogBox {
    .btn_contain {
      .sp_name {
        color: #0aa29b;
        padding: 0 5px;
        line-height: 1.5;
        cursor: pointer;
      }
      .sp_diver {
        margin: 0 10px;
        color: #1b9d97;
      }
    }
    .name_contain {
      display: flex;
      .is_home {
        margin-left: 10px;
        width: 32px;
        height: 18px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #0aa29b;
        font-size: 12px;
        color: #0aa29b;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 12px;
      }
    }
    .tableline {
      cursor: pointer;
      font-size: 14px;
      color: #0aa29b;
      text-decoration: underline;
      line-height: 14px;
    }
    .tip {
      font-size: 12px;
      color: #333333;
      line-height: 12px;
    }
  }
  // .dialogVisible2_contain {
  //   display: flex;
  //   justify-content: center;
  //   .left {
  //     margin-right: 40px;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     .code {
  //       width: 155px;
  //       height: 155px;
  //       margin-bottom: 10px;
  //     }
  //   }
  //   .right {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     .url {
  //       box-sizing: border-box;
  //       width: 370px;
  //       height: 155px;
  //       background: #f5f5f5;
  //       border-radius: 6px;
  //       padding: 18px 20px;
  //       margin-bottom: 10px;
  //     }
  //   }
  // }
}
</style>
